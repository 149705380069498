function moveNext(container: Element): void {
  const firstEleemtn = container.querySelector('div:first-child');
  if (firstEleemtn && container.scrollLeft + window.innerWidth >= container.scrollWidth) {
    container.appendChild(firstEleemtn);
  }

  container.scrollBy({
    left: window.innerWidth,
    behavior: 'smooth',
  });
}

function moveBack(container: Element): void {
  const lastElement = container.querySelector('div:last-child');
  if (lastElement && container.scrollLeft <= window.innerWidth) {
    container.prepend(lastElement);
  }

  container.scrollBy({
    left: -window.innerWidth,
    behavior: 'smooth',
  });
}

type Timer = ReturnType<typeof setInterval>;
function autoPlay(wrapper: Element, container: Element): Timer {
  let isPaused = false;
  wrapper.addEventListener('mouseover', () => {
    isPaused = true;
  });

  wrapper.addEventListener('mouseleave', () => {
    isPaused = false;
  });

  return setInterval(() => {
    if (isPaused) {
      return;
    }
    moveNext(container);
  }, 5000);
}

export function initConfidenceBarNavigation(): void {
  const wrapper = document.querySelector('.confidence-banner-slider');
  const container = document.querySelector('.swiper-wrapper');
  const next = document.querySelector('.swiper-button-next');
  const prev = document.querySelector('.swiper-button-prev');

  if (container && next && prev) {
    next.addEventListener('click', () => moveNext(container));
    prev.addEventListener('click', () => moveBack(container));
  }

  if (wrapper && container) {
    autoPlay(wrapper, container);
  }
}
