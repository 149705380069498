export function setUpToggleAllRowsHandler(accordionContainer: HTMLElement | null): void {
  if (!accordionContainer) {
    return;
  }

  const expandAllButton = document.querySelector<HTMLButtonElement>('button.expand-all');
  const collapseAllButton = document.querySelector<HTMLButtonElement>('button.collapse-all');
  const accordionContent = accordionContainer.querySelectorAll('.accordion-content');

  if (!expandAllButton || !collapseAllButton) {
    return;
  }

  expandAllButton.addEventListener('click', () => {
    accordionContent.forEach((element) => {
      jQuery(accordionContainer).foundation('down', jQuery(element));
      expandAllButton.style.display = 'none';
      collapseAllButton.style.display = 'block';
    });
  });

  collapseAllButton.addEventListener('click', () => {
    accordionContent.forEach((element) => {
      jQuery(accordionContainer).foundation('up', jQuery(element));
      collapseAllButton.style.display = 'none';
      expandAllButton.style.display = 'block';
    });
  });
}
