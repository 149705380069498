const hanldeFiltersFormSubmit = function (e: Event) {
  e.preventDefault();

  const target = e.target;

  if (!(target instanceof HTMLFormElement)) {
    return;
  }

  const data = new FormData(target);

  const object: Record<string, FormDataEntryValue | FormDataEntryValue[]> = {};
  data.forEach((value, key) => {
    if (!Reflect.has(object, key)) {
      object[key] = value;
    } else {
      if (!Array.isArray(object[key])) {
        object[key] = [object[key] as FormDataEntryValue];
      }
      (object[key] as FormDataEntryValue[]).push(value);
    }
  });

  const searchParams = new URLSearchParams(window.location.search);

  Array.from(searchParams.keys()).forEach((key) => {
    if (!Reflect.has(object, key)) {
      searchParams.delete(key);
    }
  });

  Object.keys(object).forEach((key) => {
    searchParams.delete(key);
    const entry = object[key];
    if (Array.isArray(entry)) {
      entry.forEach((value) => {
        searchParams.append(key, value.toString());
      });
      return;
    }

    searchParams.set(key, entry.toString());
  });

  window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);

  const ajaxUrl = new URL(`/wp-admin/admin-ajax.php?${searchParams.toString()}`, window.location.origin);

  ajaxUrl.searchParams.set('action', 'embark_marketplace_products');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ajaxUrl.searchParams.set('_ajax_nonce', window.embark.MARKETPLACE_NONCE);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ajaxUrl.searchParams.set('page', window.embark.PAGE);
  ajaxUrl.searchParams.set('_permalink', `${window.location.pathname}?${searchParams.toString()}`);

  const button = target.querySelector('[type="submit"]');
  if (button instanceof HTMLButtonElement) {
    button.disabled = true;
    button.setAttribute('data-busy', 'true');
  }

  fetch(ajaxUrl)
    .then((r) => {
      if (!r.ok) {
        throw {
          status: r.status,
          statusText: r.statusText,
          response: r,
        };
      }
      return r;
    })
    .then((response) => response.json())
    .then((json) => {
      const { html } = json.data;
      const products = document.querySelector('.content-area');
      if (!products) {
        return;
      }

      const el = document.createElement('div');
      el.innerHTML = html;
      const responseContainer = el.firstElementChild;
      if (responseContainer) {
        products.replaceWith(responseContainer);
      }

      const nearestCloseButton = target?.closest('.drawer')?.querySelector('[data-drawer-close]');
      if (nearestCloseButton instanceof HTMLElement) {
        nearestCloseButton.click();
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
    })
    .finally(() => {
      if (button instanceof HTMLButtonElement) {
        button.disabled = false;
        button.removeAttribute('data-busy');
      }
    });
};

const clearForm = (e: Event): void => {
  e.preventDefault();

  const target = e.target;

  if (!(target instanceof HTMLFormElement)) {
    return;
  }

  Array.from(target.elements).forEach((element) => {
    if (element instanceof HTMLInputElement) {
      element.type === 'checkbox' ? (element.checked = false) : (element.value = '');
    } else if (element instanceof HTMLTextAreaElement) {
      element.value = '';
    } else if (element instanceof HTMLSelectElement) {
      element.selectedIndex = 0;
    }
  });
};

export function initFiltersHandler() {
  const filtersForm = document.getElementById('filters_form');
  if (!(filtersForm instanceof HTMLFormElement)) {
    return;
  }

  filtersForm.addEventListener('reset', clearForm);
  filtersForm.addEventListener('submit', hanldeFiltersFormSubmit);
}
