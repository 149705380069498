function handleButtonClick(event: Event): void {
  const clickedButton = event.currentTarget as HTMLButtonElement;

  const parentElement = clickedButton.parentElement;

  if (!parentElement || !parentElement.children) {
    return;
  }

  const siblingButtons = Array.from(parentElement.children)?.filter(
    (child) => child !== clickedButton && child.tagName === 'BUTTON'
  ) as HTMLButtonElement[];

  clickedButton.setAttribute('aria-current', 'true');
  siblingButtons.forEach((button) => {
    button.removeAttribute('aria-current');
  });
}

export function initializeCarouselButtons(): void {
  const buttons = document.querySelectorAll('.orbit nav.orbit-bullets button');

  buttons.forEach((button) => {
    button.addEventListener('click', handleButtonClick);
  });
}
