function canObserveIntersection(): boolean {
  return 'IntersectionObserver' in window;
}

function isElementInViewport(element: Element): boolean {
  const rect = element.getBoundingClientRect();
  return rect.top <= window.innerHeight && rect.top + rect.height >= 0;
}

function activateAnimationWhenInViewport(elements: NodeListOf<Element>, activationClass = 'animate'): void {
  const intersectionThreshold = 0.4;

  if (!canObserveIntersection()) {
    return;
  }

  elements.forEach((element) => {
    if (isElementInViewport(element)) {
      return;
    }

    element.classList.remove('animate');
  });

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > intersectionThreshold) {
          entry.target.classList.add(activationClass);
        }
      });
    },
    {
      root: null,
      threshold: 0.4,
    }
  );

  elements.forEach((element) => {
    observer.observe(element);
  });
}

function adjustDecodedSVGTextPositionOnSafari(): void {
  const isiOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (!isSafari && !isiOS) {
    return;
  }

  const consumerTextContainerIdsToUpdate = [
    'Decoded-The-MC5R-ge',
    'Decoded-The-ACSL4-g',
    'Decoded-The-MC5R-ge',
    'Decoded-The-MC1R-ge',
    'Decoded-The-EPAS1-g',
    'Decoded-The-SLC2A9',
    'Decoded-The-KRT71-g',
    'Your-pup’s-facial-fu',
    'Find-out-if-your-dog-Copy-2',
    'You-might-have-a-bod',
    'Your-pup-can-climb-t',
    'Common-in-certain-br',
    'You-might-be-jealous',
  ];

  const breederTextContainerIdsToUpdate = [
    'Decoded:-The-MC5R-ge-Copy',
    'Decoded:-The-PMEL-ge',
    'Decoded:-The-MITF-ge',
    'An-insertion-in-the',
    'Decoded:-The-KRT71-g',
    'Decoded:-The-FGF5-ge',
    'Find-out-if-your-dog-Copy-3',
    'Merle-arises-from-an',
    'A-curly-coat-is-the',
    'A-long-coat-is-cause',
    'Decoded:-The-RALY-ge',
    'The-N-variant-causes',
  ];

  consumerTextContainerIdsToUpdate
    .concat(breederTextContainerIdsToUpdate)
    .map((id) => document.getElementById(id))
    .filter((element): element is HTMLElement => element != null)
    .map((textContainer) =>
      textContainer.querySelectorAll('tspan').forEach((tspan) => tspan.setAttribute('dy', '-35px'))
    );
}

export function initInteractiveBlock(): void {
  const interactiveBlocks = document.querySelectorAll('.interactive-chromies-block');
  if (!interactiveBlocks.length) {
    return;
  }
  activateAnimationWhenInViewport(interactiveBlocks);
  adjustDecodedSVGTextPositionOnSafari();
}
