export function initCareersSearchFilters(): void {
  if (document.querySelector('#jazzHRJobs')) {
    bindSearchFilterSelects();
  }
}

interface TargetAndDataAttrs {
  targetElement: string;
  dataAttrTarget: string;
}

function getFilterSelectboxBindTargets(): Record<string, TargetAndDataAttrs> {
  return {
    '#filterDepartments': {
      targetElement: '.job-listing-wrapper',
      dataAttrTarget: 'data-department',
    } as TargetAndDataAttrs,
    '#filterTypes': {
      targetElement: '.job-listing',
      dataAttrTarget: 'data-type',
    } as TargetAndDataAttrs,
    '#filterLocations': {
      targetElement: '.job-listing',
      dataAttrTarget: 'data-city',
    } as TargetAndDataAttrs,
  };
}

function bindSearchFilterSelects(): void {
  const selectboxBindTargets = getFilterSelectboxBindTargets();

  for (const [target, values] of Object.entries(selectboxBindTargets)) {
    jQuery(`${target}`).change(function () {
      if (!(this instanceof HTMLSelectElement)) {
        return;
      }
      const selectedValue = this.value;

      if (selectedValue === 'all') {
        jQuery(`${values.targetElement}`).removeClass('hide');
      }

      filterDisplayedJobs();
      maybeToggleClearButton();
    });
  }

  jQuery('#clearFilters').click(function () {
    const selectboxBindTargetKeys: string[] = Object.keys(getFilterSelectboxBindTargets());
    for (const selectBox of selectboxBindTargetKeys) {
      jQuery(selectBox).val('all');
      jQuery(selectBox).change();
    }
    updateJobsCounters();
  });
}

function filterDisplayedJobs(): void {
  const department = jQuery('#filterDepartments').val();
  const type = jQuery('#filterTypes').val();
  const location = jQuery('#filterLocations').val();

  if (department === 'all') {
    jQuery('[data-department]').removeClass('hide');
  } else {
    jQuery('[data-department]').addClass('hide');
    jQuery(`[data-department=${department}]`).removeClass('hide');
  }

  const allTypes = jQuery('[data-type]');
  allTypes.addClass('hide');

  if (type === 'all' && location === 'all') {
    allTypes.removeClass('hide');
    return;
  }

  if (type === 'all' && location !== 'all') {
    jQuery(`[data-city=${location}]`).removeClass('hide');
    return;
  }

  if (location === 'all' && type !== 'all') {
    jQuery(`[data-type=${type}]`).removeClass('hide');
    return;
  }

  jQuery(`[data-type=${type}][data-city=${location}]`).removeClass('hide');
}

function maybeToggleClearButton(): void {
  let disabledButton = true;
  jQuery('.jazzhr-filter select').each(function () {
    if (jQuery(this).find('option:selected').val() !== 'all') {
      disabledButton = false;
    }
  });

  jQuery('#clearFilters').prop('disabled', disabledButton);
  updateJobsCounters();
}

function updateJobsCounters(): void {
  jQuery('.job-listing-wrapper').each(function () {
    const visibleItems: number = jQuery(this).find('.job-listing:visible').length;
    if (visibleItems === 0) {
      // jQuery(this).addClass('hide');
    } else {
      jQuery(this).removeClass('hide');
    }
    jQuery(this).find('.displayed-count').text(visibleItems);
  });
}
