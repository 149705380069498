import { EV_API_URL } from './config';

/**
 * Generate a URL to provided theme assets
 *
 * @param path
 * @return {string}
 */
export function assetUrl(path) {
  // remove slashes from the path
  path = path.replace(/^\/+|\/+$/, '');
  let assetsDirUri;

  if (typeof EmbarkSite !== 'undefined' && !!EmbarkSite.assets_path) {
    assetsDirUri = EmbarkSite.assets_path;
  } else {
    assetsDirUri = '/wp-content/themes/embarkvet-theme/dist';
  }

  return `${assetsDirUri}/${path}`;
}

export function myevUrl(path) {
  path = path.replace(/^\/+|\/+$/, '');
  return `${EV_API_URL}/${path}`;
}

/**
 *
 * @param element {HTMLElement | null}
 * @param selector {string | null}
 */
export function getMatchingParent(element, selector = null) {
  let parent = element.parentElement;

  if (!selector) {
    return parent;
  }

  while (parent) {
    if (parent.matches(selector)) {
      return parent;
    }

    parent = parent.parentElement;
  }

  return parent;
}
