// @link https://github.com/DefinitelyTyped/DefinitelyTyped/blob/c4bd380cda7d82efbfa5777dd563ba9810f034a6/types/google.analytics/index.d.ts#L560
export interface EventPropertiesType {
  eventCategory: string;
  eventAction: string;
  eventLabel?: string | undefined;
  eventValue?: number | undefined;
  nonInteraction?: boolean | undefined;
  [key: string]: any;
}

interface EventObjectType extends EventPropertiesType {
  event: string;
}

export function pushGTMCustomEvent(eventName: string, eventProperties: EventPropertiesType): void {
  window.dataLayer = window.dataLayer || [];

  const eventObject: EventObjectType = {
    event: eventName,
    ...eventProperties,
  };

  window.dataLayer.push(eventObject);
}
