import { Accordion } from 'foundation-sites/js/foundation.accordion';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';

const handleResponsiveAccordions = (accordions: NodeListOf<HTMLElement>): void => {
  accordions.forEach((accordion: HTMLElement): void => {
    const breakpoint = accordion.dataset.accordionFor || null;

    if (!breakpoint) {
      return;
    }

    const $accordion = jQuery(accordion);
    const accordionItem = $accordion.find('[data-accordion-item]');
    const accordionContent = $accordion.find('[data-tab-content]');

    if (MediaQuery.is(breakpoint)) {
      accordion.classList.add('accordion');
      accordionItem && accordionItem.addClass('accordion-item');
      accordionContent && accordionContent.addClass('accordion-content');
      new Accordion($accordion, {
        allowAllClosed: true,
      });
    } else {
      accordion.classList.remove('accordion');
      accordionItem && accordionItem.removeClass('accordion-item');
      accordionContent && accordionContent.removeClass('accordion-content');
      if (typeof $accordion.data('zfPlugin') !== 'undefined') {
        $accordion.foundation('_destroy');
      }
    }
  });
};

export const initResponsiveAccordions = (): void => {
  const accordions = document.querySelectorAll<HTMLElement>('[data-accordion-for]');

  if (accordions.length < 1) {
    return;
  }

  handleResponsiveAccordions(accordions);
  jQuery(window).on('changed.zf.mediaquery', () => {
    handleResponsiveAccordions(accordions);
  });
};

export const removeAriaInContent = (accordionItems: NodeListOf<HTMLElement>): void => {
  accordionItems.forEach((section) => {
    section.removeAttribute('role');
  });
};
