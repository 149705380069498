export function dogCarouselSubscribeToScroll() {
  const $ = jQuery;
  const isDogCarousel = !!$('#dog-carousel nav.orbit-bullets').length;

  $(window).scroll(function () {
    const scrollTop = $(window).scrollTop();
    if (isDogCarousel) {
      const carouselNav = $('#dog-carousel').offset().top - 62;

      if (scrollTop >= carouselNav) {
        $('#dog-carousel nav.orbit-bullets').addClass('fixed');
        $('.nav-placeholder').show();
      } else {
        $('#dog-carousel nav.orbit-bullets').removeClass('fixed');
        $('.nav-placeholder').hide();
      }
    }
  });
}

export function breedAncestryTabs() {
  const $ = jQuery;

  // Figure out what this code does.
  $('.tabs-nav ul li').click(function () {
    if ($(this).hasClass('active')) {
      // alert('active')
    } else {
      const tab = $(this).data('tab');
      $('.tab').hide();
      $('.tab[data-tab=' + tab + ']').show();
      $('.tabs-nav ul li').removeClass('active');
      $(this).addClass('active');
    }
  });

  // Activate the Dog Carousel Tabs on click.
  $('#dog-carousel .orbit-bullets button').click(function () {
    const tab = $(this).data('tab');

    if ($(this).hasClass('is-active')) {
    } else {
      $('.breed-tab').fadeOut(400, function () {
        setTimeout(function () {
          $('.breed-tab[data-tab=' + tab + ']').fadeIn(400);
        }, 400);
      });
      $('.is-active').removeClass('is-active');
      $(this).addClass('is-active');
    }
  });
}
