export const changeAutocompleteSource = (autocompleteElement: JQuery, source: string[], headerText?: string): void => {
  autocompleteElement.autocomplete({ source });
  autocompleteElement.data('ui-autocomplete')._renderMenu = function (ul: JQuery, items: string[]) {
    items.forEach((item, index) => {
      this._renderItemData(ul, item);
      if (headerText && index === 0) ul.prepend(`<li class="autocomplete-list-header h5">${headerText}</li>`);
    });
  };
};

export const toggleAutocompleteValuesBasedOnInput = (
  autocompleteElement: JQuery,
  fullSource: string[],
  defaultSource: string[],
  defaultSearchHeader?: string
): void => {
  if (autocompleteElement.val()) {
    changeAutocompleteSource(autocompleteElement, fullSource);
  } else {
    changeAutocompleteSource(autocompleteElement, defaultSource, defaultSearchHeader);
  }
};
