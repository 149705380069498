import { Foundation } from 'foundation-sites/js/foundation.core';
import { Touch } from 'foundation-sites/js/foundation.util.touch';
import { Triggers } from 'foundation-sites/js/foundation.util.triggers';
import jQuery from 'jquery';

const mapSelectorsWithPlugins = {
  '[data-accordion]': {
    register: async () => {
      const { Accordion } = await import('foundation-sites/js/foundation.accordion');
      Foundation.plugin(Accordion, 'Accordion');
    },
  },
  '[data-accordion-menu]': {
    register: async () => {
      const { AccordionMenu } = await import('foundation-sites/js/foundation.accordionMenu');
      Foundation.plugin(AccordionMenu, 'AccordionMenu');
    },
  },
  '[data-magellan]': {
    register: async () => {
      const { Magellan } = await import('foundation-sites/js/foundation.magellan');
      Foundation.plugin(Magellan, 'Magellan');
    },
  },
  '[data-off-canvas]': {
    register: async () => {
      const { OffCanvas } = await import('foundation-sites/js/foundation.offcanvas');
      Foundation.plugin(OffCanvas, 'OffCanvas');
    },
  },
  '[data-orbit]': {
    register: async () => {
      const { Orbit } = await import('foundation-sites/js/foundation.orbit');
      Foundation.plugin(Orbit, 'Orbit');
    },
  },
  '[data-ResponsiveAccordionTabs]': {
    register: async () => {
      const { ResponsiveAccordionTabs } = await import('foundation-sites/js/foundation.responsiveAccordionTabs');
      Foundation.plugin(ResponsiveAccordionTabs, 'ResponsiveAccordionTabs');
    },
  },
  '[data-reveal]': {
    register: async () => {
      const { Reveal } = await import('foundation-sites/js/foundation.reveal');
      Foundation.plugin(Reveal, 'Reveal');
    },
  },
  '[data-tabs]': {
    register: async () => {
      const { Tabs } = await import('foundation-sites/js/foundation.tabs');
      Foundation.plugin(Tabs, 'Tabs');
    },
  },
};

export const setupPlugins = () => {
  Touch.init(jQuery);
  Triggers.init(jQuery, Foundation);

  function registerPlugin(selector) {
    return new Promise((resolve) => {
      if (!mapSelectorsWithPlugins.hasOwnProperty(selector) || !document.querySelector(selector)) {
        resolve();
        return;
      }

      const { register } = mapSelectorsWithPlugins[selector];
      register().then(resolve);
    });
  }

  return Promise.all(Object.keys(mapSelectorsWithPlugins).map(registerPlugin));
};

export const setup = () => {
  // Add Foundation to the global jQuery object, then initialize it
  Foundation.addToJquery(jQuery);

  // setup Plugins
  setupPlugins()
    .then(() => {
      // Once all the required plugins are imported, init foundation
      jQuery(document).foundation();

      jQuery('#faqExpandAllLink').click((e) => {
        e.preventDefault();
        jQuery('.faq-list.show-for-small-only').foundation('down', jQuery('.accordion-content'));
      });
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });
};

export default Foundation;
