declare global {
  interface Window {
    embarkConditionDisclaimerStrings: string[] | undefined;
  }
}

type HealthConditionDisclaimer = {
  [k: string]: {
    anchor_text: string;
    description_markup: string;
  };
};

type HealthCondition = {
  health_id: string;
  is_linkage_test?: boolean;
  is_linkage_test_for_v5?: string;
};

function getDisclaimerTypeByCondition(healthCondition: HealthCondition): string {
  if (healthCondition?.is_linkage_test === true || healthCondition?.is_linkage_test_for_v5 === 'TRUE') {
    return 'linkageTest';
  }
  if (healthCondition?.health_id === '090400') {
    return 'sod1a';
  }
  return '';
}

function getDisclaimerAnchor(targetID: string, anchorText: string): JQuery<HTMLElement> {
  return jQuery(`
    <h6 class="disclaimer--header">
      <a href="#${targetID}">${anchorText}*</a>
    </h6>
  `);
}

function getDisclaimerMarkup(targetID: string, descriptionMarkup: string): JQuery<HTMLElement> {
  return jQuery(`
    <div id="${targetID}" class="disclaimerDescriptionText">
      ${descriptionMarkup.replace(/(<strong.*?>)/, '$1* ')}
    </div>
  `);
}

export function maybeAddDisclaimer(
  condition: HealthCondition,
  attachElement: JQuery<HTMLElement>,
  disclaimerStringsGroup: HealthConditionDisclaimer,
  location = ''
): void {
  const disclaimerType = getDisclaimerTypeByCondition(condition);
  if (!disclaimerType || !attachElement) {
    return;
  }

  if (
    (typeof disclaimerStringsGroup !== 'undefined' && !disclaimerStringsGroup.hasOwnProperty(disclaimerType)) ||
    (!disclaimerStringsGroup[disclaimerType].hasOwnProperty('anchor_text') &&
      !disclaimerStringsGroup[disclaimerType].hasOwnProperty('description_markup'))
  ) {
    return;
  }

  const disclaimerTargetID = `disclaimerDescripton-${condition.health_id}-${location}`;
  const disclaimerStrings = disclaimerStringsGroup[disclaimerType];

  const disclaimerLink = getDisclaimerAnchor(disclaimerTargetID, disclaimerStrings.anchor_text);
  const disclaimerText = getDisclaimerMarkup(disclaimerTargetID, disclaimerStrings.description_markup);

  attachElement.prepend(disclaimerLink);
  attachElement.append(disclaimerText);
}
