export function initInlineVideoIframe(): void {
  const playButtons = document.querySelectorAll('[data-play-button]');
  playButtons.forEach((playButton) => {
    if (!(playButton instanceof HTMLElement)) {
      return;
    }

    playButton.addEventListener('click', () => {
      const targetVideoSelector = playButton.dataset.video || '';
      const video = document.querySelector(targetVideoSelector);

      if (!video) {
        return;
      }

      const src = video.getAttribute('data-src') || '';
      video.setAttribute('src', src);
      video.removeAttribute('hidden');
    });
  });
}
